import React, { useState, useRef, useEffect } from 'react';
import getFirebase from '../../../components/firebase';
const firebase = getFirebase();

function AwsSnsContent() {

    const handlebounce = () => {
      const query = {
        token: ""
      }
      firebase.functions().httpsCallable('bouncehandler')({
        query
      }).then(result => {
        if (result.data) {
          console.log("RESULTADO", result.data)
        }
      });
    }

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        if (!ref.current) {
          ref.current = true;
          handlebounce()
          console.log("YEYEYEYYE")
        }
        ref.current = value
      }, [value]);
      return ref.current;
    }

    const prevValue = usePrevious({});
    useEffect(() => {
      if (prevValue) {

      }
    }, [])

    return (
      <div>
        cenas
      </div>
    )
}

const Found = () => (
    <div>
        <AwsSnsContent/>
    </div>
)

export default Found